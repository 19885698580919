import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { UIKit } from "@egdeconsulting/ekom_lib";

export const FileUploaderView = (props: {
  fileChanged: (file?: File) => void;
  maxFileSizeInBytes: number;
}) => {
  const [fileFromUser, setFileFromUser] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onDropFile = (acceptedFile: File[]) => {
    if (acceptedFile.length !== 1) {
      setErrorMessage(
        "Du kan kun laste opp en fil. Trykk her for å laste opp en fil."
      );
    } else if (acceptedFile[0].size > props.maxFileSizeInBytes) {
      setErrorMessage(
        "Filen er for stor. Maksimal filstørrelse er " +
          props.maxFileSizeInBytes / 1024 / 1024 +
          "MB. Klikk her for å velge en annen fil."
      );
    } else {
      setFileFromUser(acceptedFile[0]);
      setErrorMessage(undefined);
      props.fileChanged(acceptedFile[0]);
    }
  };

  const onDeleteFile = () => {
    setFileFromUser(undefined);
    props.fileChanged(undefined);
  };

  const getValidationText = (isDragActive: boolean) => {
    if (errorMessage) {
      return <p>Obs! {errorMessage}</p>;
    }
    if (isDragActive) {
      return <p>Slipp filen her</p>;
    }
    return (
      <div className="uk-text-center">
        <p className="uk-h3">Dra filen hit</p>
        <p>eller</p>
        <UIKit.Button color="secondary">Finn fil</UIKit.Button>
      </div>
    );
  };

  return (
    <div>
      {!fileFromUser ? (
        <Dropzone onDrop={onDropFile} maxFiles={1}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className="file-upload uk-placeholder uk-flex uk-flex-middle uk-flex-center"
                style={{ minHeight: "275px" }}
              >
                <input {...getInputProps()} />
                {getValidationText(isDragActive)}
              </div>
            );
          }}
        </Dropzone>
      ) : (
        <div className="uk-alert uk-flex uk-flex-middle">
          <span>
            {fileFromUser.name +
              " (" +
              Math.round(fileFromUser.size / 1024) +
              " Kb)"}
          </span>
          <UIKit.Button className="uk-padding-remove-horizontal">
            <span
              title="Fjern fil"
              onClick={() => onDeleteFile()}
              className="uk-margin-small-left uk-margin-small-right"
              uk-icon="trash"
            ></span>
          </UIKit.Button>
        </div>
      )}
    </div>
  );
};
