import React, { useEffect, useState } from "react";
import { State } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { getDataByOrganisationNumber } from "../export.service";
import { handleHasRole } from "../../common/utilities/roleHandler";
import { hasRole, DestructiveModalView, UIKit } from "@egdeconsulting/ekom_lib";
import { unAuthorizedRole } from "../../common/user/user.action";
import { Navigate } from "react-router";
import { deleteElementsByOwnerId } from "../../delete/delete.service";

const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";

export const Export = () => {
  const dispatch = useDispatch();

  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) ?? ""
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);
  const getOrgDataLoading = useSelector(
    (state: State) => state.exportProducts.fetchOrgDataByOrgNrLoading
  );
  const deleteDataLoading = useSelector(
    (state: State) => state.deleteProduct.deleteProductsByOwnerIdLoading
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [onDelete, setOnDelete] = useState<Function>(() => {});
  const [deleteTitle, setDeleteTitle] = useState<string | undefined>(undefined);
  const [deleteDescription, setDeleteDescription] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    handleHasRole();
  }, []);

  const handleExport = (isInfrastructure: boolean, orgNr: string) => {
    dispatch<any>(
      getDataByOrganisationNumber(isInfrastructure, access_token, orgNr)
    );
  };

  const handleDelete = (isInfrastructure: boolean, orgNr: string) => {
    dispatch<any>(
      deleteElementsByOwnerId(access_token, orgNr, isInfrastructure)
    );
    setShowDeleteDialog(false);
  };

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      return (
        <UIKit.Section>
          <UIKit.Container>
            <h1>Eksporter eller slett data registrert i Ekomportalen</h1>
            {getOrgDataLoading || deleteDataLoading ? (
              <UIKit.Spinner>
                {" "}
                {deleteDataLoading ? "Sletter data" : "Henter fil"}
              </UIKit.Spinner>
            ) : (
              <UIKit.Grid match={true} className="uk-child-width-1-2@s">
                <div className="uk-padding-large">
                  <h3 className="uk-text-left uk-margin-remove-bottom">
                    Eksporter eller slett infrastruktur
                  </h3>
                  <div className="uk-margin-bottom uk-text-bold">
                    {activeRole.name}
                  </div>

                  <UIKit.Button
                    onClick={() =>
                      activeRole && handleExport(true, activeRole.id)
                    }
                    color="primary"
                    className="uk-width-small"
                  >
                    Last ned
                  </UIKit.Button>
                  <UIKit.Button
                    color="link"
                    className="uk-margin-top uk-width-small destructive"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteTitle("Slette all infrastruktur?");
                      setDeleteDescription(
                        "Alle infrastrukturobjekter i databasen slettes permanent."
                      );
                      if (activeRole)
                        setOnDelete(() => handleDelete(true, activeRole.id));
                    }}
                  >
                    Slett {<span uk-icon="icon: trash; ratio: 1"></span>}
                  </UIKit.Button>
                </div>

                <div className="uk-padding-large">
                  <h3 className="uk-text-left uk-margin-remove-bottom">
                    Eksporter eller slett bygge- og anleggsarbeid
                  </h3>
                  <div className="uk-margin-bottom uk-text-bold">
                    {activeRole.name}
                  </div>
                  <UIKit.Button
                    onClick={() =>
                      activeRole && handleExport(false, activeRole.id)
                    }
                    color="primary"
                    className="uk-width-small"
                  >
                    Last ned
                  </UIKit.Button>
                  <UIKit.Button
                    color="link"
                    className="uk-margin-top uk-width-small destructive"
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setDeleteTitle("Slette alle bygge- og anleggsarbeid?");
                      setDeleteDescription(
                        "Alle bygge- og anleggsarbeid i databasen slettes permanent."
                      );
                      if (activeRole)
                        setOnDelete(() => handleDelete(false, activeRole.id));
                    }}
                  >
                    Slett {<span uk-icon="icon: trash; ratio: 1"></span>}
                  </UIKit.Button>
                </div>
              </UIKit.Grid>
            )}
            <DestructiveModalView
              show={showDeleteDialog}
              closeButtonText="Avbryt"
              onClose={() => setShowDeleteDialog(false)}
              title={deleteTitle ?? ""}
              description={deleteDescription ?? ""}
              submitButtonText="Slett all data"
              onSubmit={onDelete}
            />
          </UIKit.Container>
        </UIKit.Section>
      );
    }
  }
  dispatch(
    unAuthorizedRole(
      "For å eksportere må du ha rollen: " + importRoleName,
      new Error("")
    )
  );
  return <Navigate to="/" />;
};

export default Export;
