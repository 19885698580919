import React from "react";
import { connect } from "react-redux";
import { State } from "../../../redux/rootReducer";
import { getUserRoles } from "../../user/user.service";
import { IUserRoles } from "../../user/IUser";
import { setActiveRole } from "../../user/user.action";
import { Navigate } from "react-router";
import { hasRole, UIKit } from "@egdeconsulting/ekom_lib";

type RoleProps = {
  access_token: string;
  dispatch: Function;
  getRolesLoading: boolean;
  roles?: IUserRoles[];
};

class Role extends React.Component<RoleProps, {}> {
  componentDidMount() {
    this.props.dispatch(getUserRoles(this.props.access_token));
  }

  render() {
    var requestedActiveRole = window.location.href
      .split("role/")
      .pop()
      ?.split("/");
    if (this.props.roles && !this.props.getRolesLoading) {
      if (requestedActiveRole && requestedActiveRole.length === 2) {
        const requestedRoleName = requestedActiveRole[0];
        const requstedRoleId = requestedActiveRole[1];
        const role = this.props.roles.find((r) => r.id === requstedRoleId);
        if (role) {
          if (hasRole(requestedRoleName, role)) {
            this.props.dispatch(setActiveRole(role));
            return <Navigate to="/" />;
          }
        }
      }
      this.props.dispatch(setActiveRole(this.props.roles[0]));
      return <Navigate to="/" />;
    } else {
      if (this.props.getRolesLoading) {
        return (
          <UIKit.Container>
            <UIKit.Spinner>Henter roller</UIKit.Spinner>
          </UIKit.Container>
        );
      }
      return <div></div>;
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    getRolesLoading: state.user.fetchRolesLoading,
    roles: state.user.roles,
  };
};
export default connect(mapStateToProps)(Role);
