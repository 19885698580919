import React, { useEffect, useState } from "react";
import { State } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { FileUploaderView } from "../../common/file/fileUpload.view";
import { postFile } from "../import.service";
import { handleHasRole } from "../../common/utilities/roleHandler";
import { hasRole, ValidationWarning, UIKit } from "@egdeconsulting/ekom_lib";
import { unAuthorizedRole } from "../../common/user/user.action";
import { Navigate } from "react-router";
import { getOrganisationByOrgNr } from "../../common/organisation/organisation.service";

const legalFileExtensions = ["csv", "json", "geojson", "sos", "gml"];
const maxUploadFileSizeBytes =
  process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE_BYTES || "29360128";
const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";
const userManualsURL = process.env.REACT_APP_USER_MANUALS_URL || "0";

export const FileImport = () => {
  const dispatch = useDispatch();
  const [fileFromUser, setFileFromUser] = useState<File | undefined>(undefined);
  const [fileExtension, setFileExtension] = useState<string | undefined>(
    undefined
  );

  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) ?? ""
  );
  const postFileLoading = useSelector(
    (state: State) => state.importProduct.postFileLoading
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);

  const fetchOrganisationLoading = useSelector(
    (state: State) => state.organisation.fetchOrganisationLoading
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );
  const fetchOrganisationError = useSelector(
    (state: State) => state.organisation.fetchOrganisationError
  );

  // access_token: (state.auth.user && state.auth.user.access_token) || "",
  //     postFileLoading: state.importProduct.postFileLoading,
  //     activeRole: state.user.activeRole,
  //     organization_name: state.user.activeRole?.name || "",
  //     fetchOrganisationLoading: state.organisation.fetchOrganisationLoading,
  //     organisation: state.organisation.organisation,
  //     fetchOrganisationError: state.organisation.fetchOrganisationError,

  const isInfrastructurePath =
    window.location.pathname.includes("infrastructure");

  useEffect(() => {
    handleHasRole();
  }, []);

  const handleFileChange = (file?: File) => {
    const fileExtension = file?.name.split(".").pop()?.toLowerCase();
    setFileFromUser(file);
    setFileExtension(fileExtension);
  };

  const handleSubmit = (orgNr: string) => {
    fileFromUser &&
      legalFileExtensions.includes(fileExtension || "") &&
      dispatch<any>(
        postFile(isInfrastructurePath, access_token, fileFromUser, orgNr)
      );
  };

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      if (
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, true)
        );
      }
      if (organisation) {
        if (isInfrastructurePath) {
          return (
            <UIKit.Section>
              <UIKit.Container>
                <div className="import">
                  <h1 className="uk-text-center">
                    Registrer infrastruktur med fil
                  </h1>
                  <p>
                    Her kan du laste opp en fil med infrastruktur som du skal
                    registrere i Ekomportalen. Tillatte formater er CSV,
                    GeoJSON, SOSI og GML. Filene kan eksporteres fra deres
                    GIS-system. Hvis dere ikke har registrert infrastrukturen
                    deres i et GIS-system, kan du laste ned gratisprogrammet
                    QGIS.
                  </p>
                  <p>
                    <a href={userManualsURL}>Gå til brukermanualer</a>
                  </p>
                  <p>
                    Hvis du ønsker å registrere infrastrukturen enkeltvis,{" "}
                    <a href={"/manual-infrastructure-import"}>
                      gå til manuell registrering
                    </a>
                    .
                  </p>
                  {postFileLoading ? (
                    <UIKit.Spinner>Laster opp</UIKit.Spinner>
                  ) : (
                    <UIKit.Grid>
                      <div className="uk-width-1-2@m">
                        <FileUploaderView
                          fileChanged={(file) => handleFileChange(file)}
                          maxFileSizeInBytes={parseInt(maxUploadFileSizeBytes)}
                        />
                        {fileFromUser &&
                          !legalFileExtensions.includes(
                            fileExtension || ""
                          ) && (
                            <ValidationWarning text="Filen har feil filformat. Kun CSV-, GeoJSON-, SOSI- og GML-filer er tillatt." />
                          )}
                        <UIKit.Button
                          disabled={
                            !legalFileExtensions.includes(
                              fileExtension || ""
                            ) || !organisation.contactInformation
                          }
                          color="primary"
                          onClick={() =>
                            activeRole && handleSubmit(activeRole.id)
                          }
                          className="uk-width-1-1 uk-width-auto@s uk-box-sizing-border-box"
                        >
                          Registrer infrastruktur
                        </UIKit.Button>
                      </div>
                    </UIKit.Grid>
                  )}
                </div>
              </UIKit.Container>
            </UIKit.Section>
          );
        } else {
          return (
            <UIKit.Section>
              <UIKit.Container>
                <div className="import">
                  <h1 className="uk-text-center">
                    Registrere og redigere bygge- og anleggsarbeider
                  </h1>
                  <h2 className="uk-text-left">
                    Manuell registrering og redigering
                  </h2>
                  <p>
                    For å registrere bygge- og anleggsarbeidene enkeltvis,{" "}
                    <a href={"/manual-construction-import"}>
                      gå til manuell registrering
                    </a>
                    .
                  </p>
                  <p>
                    Dersom du ønsker å redigere eller slette allerede manuelt
                    registrerte bygge- og anleggsarbeider,{" "}
                    <a href={"/manual-construction-edit"}>
                      gå til manuell redigering
                    </a>
                    .
                  </p>
                  <h2 className="uk-text-left">Registrering med fil</h2>
                  <p>
                    Her kan du laste opp en fil med bygge- og anleggsarbeider
                    som du skal registrere i Ekomportalen. Tillatte formater er
                    CSV, GeoJSON, SOSI og GML. Filene kan eksporteres fra deres
                    GIS-system. Dersom dere ikke har registrert bygge- og
                    anleggsarbeidene deres i et GIS-system, kan du laste ned
                    gratisprogrammet QGIS.
                  </p>
                  {postFileLoading ? (
                    <UIKit.Spinner>Laster opp</UIKit.Spinner>
                  ) : (
                    <UIKit.Grid>
                      <div className="uk-width-1-2@m">
                        <FileUploaderView
                          fileChanged={(file) => handleFileChange(file)}
                          maxFileSizeInBytes={parseInt(maxUploadFileSizeBytes)}
                        />
                        {fileFromUser &&
                          !legalFileExtensions.includes(
                            fileExtension || ""
                          ) && (
                            <ValidationWarning text="Filen har feil filformat. Kun CSV-, GeoJSON-, SOSI- og GML-filer er tillatt." />
                          )}
                        <UIKit.Button
                          disabled={
                            !legalFileExtensions.includes(
                              fileExtension || ""
                            ) || !organisation.contactInformation
                          }
                          color="primary"
                          onClick={() =>
                            activeRole && handleSubmit(activeRole.id)
                          }
                          className="uk-width-1-1 uk-width-auto@s uk-box-sizing-border-box"
                        >
                          Registrer bygge- og anleggsarbeid
                        </UIKit.Button>
                      </div>
                    </UIKit.Grid>
                  )}
                </div>
              </UIKit.Container>
            </UIKit.Section>
          );
        }
      } else if (!fetchOrganisationError)
        return (
          <UIKit.Container>
            <UIKit.Spinner>Henter firmaprofil</UIKit.Spinner>
          </UIKit.Container>
        );
      else return <div></div>;
    }
  }
  dispatch<any>(
    unAuthorizedRole(
      "For å importere må du ha rollen: " + importRoleName,
      new Error("")
    )
  );
  return <Navigate to="/" />;
};

export default FileImport;
